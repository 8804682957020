.App {
    text-align: center;
    background-color: black;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

/*
Debugging
 */
/*:focus {
    outline: 2px solid red !important;
}*/

button {
    color: black;
    background-color: #d8ccb4;
}

button:hover {
    background-color: #d8ccb4;
}

grid:hover {
    color: red;
    background-color: black;
}

.App-header {
}

.App-main {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 1.5vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

th, td {
    padding: 7px;
}

table, th, td {
    border: 1px solid white;
    border-spacing: 0px;
}

table {
    margin-top: 0px;
    margin-bottom: 20px;
}


@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.rowC {
    margin: auto;
    padding: 0px;

    display:flex;
    flex-direction:row;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropbtn {
    font-size: 50px;
}

.dropdown-content {
    display: block;
    position: absolute;
    background-color: black;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content a {
    color: black;
    background-color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-item {
    padding: 10px 20px;
    color: white;
    cursor: pointer;
    background-color: black;
    text-align: left;
}

.dropdown-item:hover {
    background-color: grey;
}

.monsterItem {
    padding: 4px 8px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.monsterItem:hover {
    background-color: white;
}

.monsterName {
    margin-top: 5px;
    margin-bottom: 10px;
}

.monsterSearch {
    margin-bottom: 0px;
}

.configurationPanel {
    margin-top: 15px;
}

.parent {
    display: flex;
    justify-content: center;  /* Horizontal centering */
    align-items: center;      /* Vertical centering (if needed) */
}


.MuiToggleButtonGroup-root {
    background-color: #d8ccb4;
    border: none;
}

.MuiToggleButton-root.MuiToggleButton-root {
    color: black;
    background-color: #d8ccb4;
    border: none;
    font-size: calc(7px + 0.5vmin);
    transition: background-color 0.3s, color 0.3s;
}

.MuiToggleButton-root.MuiToggleButton-root:hover {
    color: white;
    background-color: #d8ccb4;
}

.MuiToggleButton-root.MuiToggleButton-root.Mui-selected {
    color: white;
    background-color: black;
}

.MuiToggleButton-root.MuiToggleButton-root.Mui-selected:hover {
    background-color: black;
}


.hitpoints-defence-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end; /* This will right-align the child elements */
    justify-content: center; /* This will vertically center the items in case there's extra space */
}

:root {
    --icon-gradient: calc((50 - 32.5) / (1024 - 390));
    --icon-base: calc(32.5px - var(--icon-gradient) * 390px);
    --icon-size: min(calc(var(--icon-base) + var(--icon-gradient) * 100vw), 50px);
}

.icon-size {
    width: var(--icon-size);
    height: var(--icon-size);
}
